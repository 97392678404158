import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Paper,
  Theme,
  OutlinedInput,
  IconButton,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { BreadCrumbHeader } from '../../../../components/BreadCrumbHeader';
import { useLocation } from 'react-router';
import { UpdateUserInput } from './Container';
import { theme } from '../../../../themes/theme';
import {
  UpdateUserClinicAndDivisionRequestPairInput,
  UserIdsInBatch,
} from '../../../../app.types.generated';
import { RoleSelect } from '../components/RoleSelect';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import { ResendPasswordIcon } from '../../../../assets/Icons/MsoAdmin';
import { AccountStatus, User, UserRole } from '../../../../enum/Users';
import {
  formatePhoneNumber,
  handlePhoneNumber,
} from '../../../../sharePipe/normalizePhoneNumberInput';
import { GlobalContext } from '../../../../components/GlobalContext';
import { useCallbackPrompt } from '../../../../hooks/useCallbackPrompt';
import { LeaveEditDialogBox } from '../../../../components/DialogBox';
import { MainRegion } from '../../../../components/MainRegion';
import { useNewRoleOptions } from '../../../../hooks/useNewRoleOptions';
import { AddCircleIcon } from '../../../../assets/Icons/Users/AddCircleIcon';
import { DivisionSelect } from '../../../../components/DivisionSelect';
import { useGetClinicsAndDivisionQuery } from '../NewUsers/index.generated';
import {
  ClinicIdAndDivisionIdPairs,
  ClinicIdAndDivisionIdPairsInput,
} from '../NewUsers/View';
import { CustomSingleSelect } from '../../../../components/CustomSingleSelect';
import { CloseIcon } from '../../../../assets/Icons/ExportPatients';
import { useGetClinicAndDivisionPairsByUserIdQuery } from './index.generated';
import { useFeature } from '../../../../components/FeatureFlag';
import { useWaitForMFA } from '../../../../hooks/useWaitMFA';
import { StyledCheckbox } from '../../../../components/StyledCheckbox';
import { MFACheckbox } from '../../../../components/MFACheckbox';

type Props = {
  updateUserInfo: (input: UpdateUserInput) => Promise<void>;
  roleOptions: any[];
  resendTemporaryPassword: (
    userIds: UserIdsInBatch,
    userEmail: string
  ) => Promise<void>;
  mfaEnabled: boolean;
};

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const ITEM_NUM = 5;

const useStyles = makeStyles((theme: Theme) => ({
  checkBoxPosition: {
    padding: theme.spacing(0.25),
  },
  root: {},
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,

    '& .MuiButton-label': {},
  },
  mainGrid: {
    margin: theme.spacing(0),
    width: '100%',
  },
  numberCircle: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
  },
  card: {
    width: '100%',
    display: 'flex',
    borderRadius: 8,
  },
  cardHeader: {
    height: 72,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(0),
  },
  cardMidSection: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    padding: theme.spacing(3),
  },
  cardFooter: {
    height: 72,
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[400],
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  radioLabel: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
      fontSize: 16,
      paddingLeft: theme.spacing(1),
    },
  },
  deleteIcon: {
    color: theme.palette.grey[500],
    fontSize: 24,
  },
  modifierTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  modifierSpacing: {
    height: theme.spacing(4),
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modifierDetails: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },

  // MULTI-SELECT STYLES
  selectMenuContent: {
    display: 'block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  multipleSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'top',
    width: '100%',
  },
  formControl: {
    width: '100%',
    '& .MuiInputBase-root': {
      height: 48,
      borderRadius: 6,
      boxShadow:
        '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 0px 0px rgba(0, 0, 0, 0.05),0px 3px 6px 0px rgba(0, 0, 0, 0.08)',
    },
  },
  inputTitle: {
    height: 24,
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.7,
    marginBottom: theme.spacing(1),
  },
  inputHelper: {
    height: 20,
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.67,
    marginTop: theme.spacing(0.5),
  },
  placeholderText: {
    color: theme.palette.grey[400],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  helperText: {
    color: theme.palette.grey['500'],
  },
  // insurance select styles
  insuranceSelect: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
  },
  insurancePlaceholderTextSelected: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(1),
  },
  insuranceMenuItem: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  insuranceMenuItemText: {
    height: 24,
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.71,
  },
  insuranceList: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    background: theme.palette.primary.contrastText,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),

    '& .MuiListItem-root': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    '& li .MuiMenuItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  insuranceListItem: {
    borderBottom: '1px solid #d1d1d1',
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
    },
  },
  insuranceListItemText: {
    height: 24,
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  },
  insuranceListitemHelper: {
    height: 16,
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.33,
  },
  subLabelError: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0',
    margin: 'auto 0',
  },
}));

export type NewUserForm = {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  userId: string;
  phone: string;
};

export const EditUserView = (props: Props) => {
  const {
    setToastMessage,
    setIsOpenToast,
    setMFADialogState,
    MFADialogState,
    toastMessage,
  } = React.useContext(GlobalContext);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog
  );
  const location = useLocation();
  const outlinedInputClasses = useOutlinedInputStyles();
  const passingData = location.state as any;
  const userData = passingData.userData as User;
  const getClinicAndDivisionByUserIDQuery = useGetClinicAndDivisionPairsByUserIdQuery(
    {
      variables: {
        input: {
          userID: userData.userId,
        },
      },
    }
  );

  // React.useEffect(() => {
  //   console.log('userdata', userData);
  // }, [userData]);

  useEffect(() => {
    if (
      getClinicAndDivisionByUserIDQuery?.data?.getClinicAndDivisionPairsByUserID
    ) {
      const list =
        getClinicAndDivisionByUserIDQuery?.data
          ?.getClinicAndDivisionPairsByUserID;
      const data = list.map((item) => {
        return {
          clinicId: item.clinicID,
          divisions: item.divisions
            .filter((item: any) => item.divisionName && item.divisionID)
            .map((division: any) => {
              return division.divisionID;
            }),
        };
      });
      const selectedClinicIDString = data
        .map((item: any) => item.clinicId)
        .join(',');
      // console.log('setSelectedClinicIdAndDivisionIdPairs', data);
      setNumberOfPairs(data.length);
      setSelectedClinicId(selectedClinicIDString);
      setSelectedClinicIdAndDivisionIdPairs(data);
    }
  }, [
    getClinicAndDivisionByUserIDQuery?.data?.getClinicAndDivisionPairsByUserID,
  ]);

  const urlParams = passingData.urlParams;

  const classes = useStyles();
  const navigate = useNavigate();
  const [role, setRole] = useState<UserRole>();
  const clinicsAndDivisionQuery = useGetClinicsAndDivisionQuery({
    variables: {},
  });
  const [clinicIdOptions, setClinicIdOptions] = useState<any[]>([]);
  const [divisionIdOptions, setDivisionIdOptions] = useState<any[]>([]);
  const [
    selectedClinicIdAndDivisionIdPairs,
    setSelectedClinicIdAndDivisionIdPairs,
  ] = useState<ClinicIdAndDivisionIdPairs[]>([
    {
      clinicId: undefined,
      divisions: [],
    },
  ]);
  const [selectedClinicId, setSelectedClinicId] = useState<string>('');
  const [numberOfPairs, setNumberOfPairs] = useState<number>(0);
  const [disabledDivision, setDisabledDivision] = useState<boolean[]>([]);
  const [
    disableDivisionSelection,
    setDisableDivisionSelection,
  ] = useState<string>('');
  // console.log(
  //   'selectedClinicIdAndDivisionIdPairs',
  //   selectedClinicIdAndDivisionIdPairs
  // );
  // console.log('disabledDivision', disabledDivision);
  React.useEffect(() => {
    const tmp = [];
    if (selectedClinicIdAndDivisionIdPairs.length > 0) {
      // let string = '';
      for (let i = 0; i < selectedClinicIdAndDivisionIdPairs.length; i++) {
        if (selectedClinicIdAndDivisionIdPairs[i].clinicId != undefined) {
          // string = string + i + ',';
          tmp.push(false);
        } else {
          tmp.push(true);
        }
      }
      setDisabledDivision(tmp);
      // setDisableDivisionSelection(string);
    }
  }, [JSON.stringify(selectedClinicIdAndDivisionIdPairs)]);
  const handleClinicIdOrDivisionChange = (type: string, newValue: any) => {
    const idxString = type.split(',')[0];
    const idx = parseInt(idxString, 10);
    const idType = type.split(',')[1];
    let temp: any = [];
    if (idType == 'ClinicId') {
      temp = selectedClinicIdAndDivisionIdPairs;
      temp[idx].clinicId = newValue;
      temp[idx].divisions = [];
      setSelectedClinicIdAndDivisionIdPairs(temp);
      let string = '';
      for (let i = 0; i < temp.length; i++) {
        string = string + i + ',';
      }
      setDisableDivisionSelection(string);
      let string2 = '';
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].clinicId) {
          string2 = string2 + temp[i].clinicId + ',';
        }
      }
      setSelectedClinicId(string2);
    } else if (idType == 'DivisionId') {
      temp = selectedClinicIdAndDivisionIdPairs;
      temp[idx].divisions = newValue;
      setSelectedClinicIdAndDivisionIdPairs(temp);
    }
  };

  React.useEffect(() => {
    if (clinicsAndDivisionQuery?.data?.getCareGapFilterOptions) {
      setClinicIdOptions(
        clinicsAndDivisionQuery?.data?.getCareGapFilterOptions?.Clinics ?? []
      );
      setDivisionIdOptions(
        clinicsAndDivisionQuery?.data?.getCareGapFilterOptions?.Divisions ?? []
      );
    }
  }, [clinicsAndDivisionQuery?.data?.getCareGapFilterOptions]);

  const {
    control,
    register,
    errors,
    formState,
    handleSubmit,
  } = useForm<NewUserForm>({
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      roleId: userData.role.roleId,
      userId: userData.userId,
      phone: formatePhoneNumber(userData.phone),
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const handleResendPassword = async (event: React.MouseEvent<unknown>) => {
    try {
      await props.resendTemporaryPassword(
        {
          userIdsInBatch: [
            {
              userId: userData.userId,
            },
          ],
        },
        userData.email
      );
    } catch (error) {
      //
    }
  };

  const checkSelectedClinicIdAndDivisionIdPairs = (
    firstName: string,
    lastName: string,
    email: string,
    roleID: string
  ) => {
    let result: UpdateUserClinicAndDivisionRequestPairInput[] = [];
    if (selectedClinicIdAndDivisionIdPairs.length > 0) {
      for (let i = 0; i < selectedClinicIdAndDivisionIdPairs.length; i++) {
        const clinicID = selectedClinicIdAndDivisionIdPairs[i].clinicId;
        if (clinicID) {
          const selectedDropdownItems =
            selectedClinicIdAndDivisionIdPairs[i].divisions?.length > 0
              ? selectedClinicIdAndDivisionIdPairs[i].divisions.includes('All')
                ? divisionIdOptions
                : selectedClinicIdAndDivisionIdPairs[i].divisions.map(
                    (division) => {
                      return divisionIdOptions.find(
                        (divisionId) => divisionId.value === division
                      );
                    }
                  )
              : [];
          if (selectedDropdownItems.length === 0) {
            // if no division is selected, then add the user to the clinic only.
            result.push({
              clinicID: clinicID,
              divisionID: '',
              divisionName: '',
              address: '',
              firstName: firstName,
              lastName: lastName,
              email: email,
              roleID: roleID,
              isSiteDesignee: false,
              userID: userData.userId,
            });
          } else {
            const clinicAndDivisionPairs: UpdateUserClinicAndDivisionRequestPairInput[] = selectedDropdownItems.map(
              (item: any) => {
                return {
                  clinicID: clinicID,
                  divisionID: item?.value,
                  divisionName: item?.name,
                  address: '',
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  roleID: roleID,
                  isSiteDesignee: false,
                  userID: userData.userId,
                };
              }
            );
            result = result.concat(clinicAndDivisionPairs);
          }
        }
      }
      return result;
    }
  };

  const isMFACompleted = useWaitForMFA(toastMessage);

  React.useEffect(() => {
    if (isMFACompleted) {
      userData.phone = phoneNumber.replaceAll('-', '');
    }
  }, [isMFACompleted]);

  const handleMFA = () => {
    return new Promise((resolve, reject) => {
      if (isMFACompleted) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  };
  const onSubmit = async (input: NewUserForm) => {
    // call api
    setShowDialog(false);
    if (userData.phone !== phoneNumber.replaceAll('-', '')) {
      // verify phone number
      setMFADialogState({
        showMFADialog: true,
        userId: userData.userId,
        phoneNumber: phoneNumber.replaceAll('-', ''),
        verifyButtonClicked: false,
        skipForNow: false,
      });
      await handleMFA();
    }
    try {
      const roleID = role?.roleId || userData.role.roleId;
      const pairs = checkSelectedClinicIdAndDivisionIdPairs(
        input.firstName,
        input.lastName,
        userData.email,
        roleID
      );
      await props.updateUserInfo({
        userId: userData.userId,
        roleId: role?.roleId || userData.role.roleId,
        roleName: userData?.roleName ?? '',
        firstName: input.firstName,
        lastName: input.lastName,
        email: userData.email,
        phone: phoneNumber.replaceAll('-', ''),
        clinicAndDivisionPairs: pairs,
      });

      // go back to users list
      navigate(`/users/all${urlParams}`);

      setToastMessage({
        snackbarMsg: `User has been updated!`,
        severity: 'success',
        isOpen: true,
      });

      setIsOpenToast(true);
    } catch (e) {
      // go back to users list
      navigate(`/users/all${urlParams}`);

      setToastMessage({
        snackbarMsg: `Fail to update user,  Please try again.`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
  };

  const [phoneNumber, setPhoneNumber] = React.useState(
    formatePhoneNumber(userData.phone)
  ); // userData.phoneNumber

  React.useEffect(() => {
    setPhoneNumber(formatePhoneNumber(userData.phone || ''));
  }, [userData.phone]);

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(handlePhoneNumber(e.target.value));
  };

  React.useEffect(() => {
    if (
      (formState.isDirty ||
        (role && role?.roleId != userData?.role?.roleId) ||
        phoneNumber?.replaceAll('-', '') != userData.phone) &&
      !showDialog
    ) {
      setShowDialog(true);
    }
  }, [formState.isDirty, role, phoneNumber]);

  const newRoleOptions = useNewRoleOptions(props.roleOptions);
  const hasFeature = useFeature('division');
  return (
    <MainRegion
      header={
        <BreadCrumbHeader
          childrenName={'Edit User'}
          rootLink={`/users/all`}
          rootName={'User & Permission'}
          isTitle={true}
          isButton={true}
          renderButton={
            <Button
              onClick={() => navigate(`/users/all${urlParams}`)}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          }
        />
      }
    >
      {/* spacing */}
      <LeaveEditDialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {/* Patient details form */}
      <div>
        <Grid container spacing={8} className={classes.mainGrid}>
          {/* spacing */}
          <Grid item xs={2} />
          <Grid item xs={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper className={classes.card} elevation={1}>
                <Grid container>
                  <Grid item xs={12} className={classes.cardHeader}>
                    {userData.status === AccountStatus.PENDING ? (
                      <>
                        <Typography className={classes.cardTitle}>
                          Pending
                        </Typography>

                        <Button
                          onClick={handleResendPassword}
                          className={classes.cancelButton}
                          style={{ marginRight: theme.spacing(3) }}
                        >
                          <ResendPasswordIcon
                            style={{ height: 24, width: 24, marginRight: 4 }}
                          />
                          <p>Resend Temporary Password</p>
                        </Button>
                      </>
                    ) : (
                      <Typography className={classes.cardTitle}>
                        Edit User
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={3}
                    className={classes.cardContent}
                  >
                    <Grid item xs={6}>
                      <Typography className={classes.inputLabel}>
                        First name
                      </Typography>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ onChange, value, name }) => (
                          <>
                            <OutlinedInput
                              fullWidth
                              // autoFocus
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: 'You must enter your name',
                                },
                                minLength: {
                                  value: 2,
                                  message: 'Name must be at least 2 characters',
                                },
                                maxLength: {
                                  value: 26,
                                  message:
                                    'Name must be less than 26 characters',
                                },
                              })}
                              name={name}
                              defaultValue={value}
                              onChange={onChange}
                              error={
                                !!errors.firstName?.message ||
                                !!errors.firstName?.type
                              }
                              className={classes.nameInput}
                              placeholder="First Name"
                              classes={outlinedInputClasses}
                            />
                            {errors.firstName &&
                              errors.firstName.type === 'required' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.firstName.message}
                                </Typography>
                              )}
                            {errors.firstName &&
                              errors.firstName.type === 'minLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.firstName.message}
                                </Typography>
                              )}
                            {errors.firstName &&
                              errors.firstName.type === 'maxLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.firstName.message}
                                </Typography>
                              )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.inputLabel}>
                        Last name
                      </Typography>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ onChange, value, name }) => (
                          <>
                            <OutlinedInput
                              fullWidth
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: 'You must enter your name',
                                },
                                minLength: {
                                  value: 2,
                                  message: 'Name must be at least 2 characters',
                                },
                                maxLength: {
                                  value: 26,
                                  message:
                                    'Name must be less than 26 characters',
                                },
                              })}
                              name={name}
                              defaultValue={value}
                              onChange={onChange}
                              error={
                                !!errors.lastName?.message ||
                                !!errors.lastName?.type
                              }
                              className={classes.nameInput}
                              placeholder="Last Name"
                              classes={outlinedInputClasses}
                            />
                            {errors.lastName &&
                              errors.lastName.type === 'required' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.lastName.message}
                                </Typography>
                              )}
                            {errors.lastName &&
                              errors.lastName.type === 'minLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.lastName.message}
                                </Typography>
                              )}
                            {errors.lastName &&
                              errors.lastName.type === 'maxLength' && (
                                <Typography className={classes.subLabelError}>
                                  {errors.lastName.message}
                                </Typography>
                              )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.inputLabel}>
                        Email address
                      </Typography>
                      <Controller
                        name="email"
                        control={control}
                        render={({ onChange, value, name }) => (
                          <OutlinedInput
                            fullWidth
                            disabled
                            inputRef={register({
                              required: true,
                              minLength: 1,
                              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            })}
                            name={name}
                            defaultValue={value}
                            onChange={onChange}
                            error={
                              !!errors.email?.message || !!errors.email?.type
                            }
                            className={classes.nameInput}
                            placeholder="Enter Email Address"
                            classes={outlinedInputClasses}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RoleSelect
                        state={role}
                        setState={setRole}
                        isMultiple={false}
                        formType={'UserRole'}
                        itemsTypes={'Item'}
                        inputTitle={'Role(s)'}
                        placeholder={'Select Title(s)'}
                        value={userData.role}
                        droplistAttr={{
                          maxHeight: ITEM_HEIGHT * ITEM_NUM + ITEM_PADDING_TOP,
                        }}
                        items={newRoleOptions}
                        classes={{
                          multipleSelect: classes.multipleSelect,
                          formControl: classes.formControl,
                          inputTitle: classes.inputTitle,
                          placeholder: classes.placeholderText,
                          placeholderSelected:
                            classes.insurancePlaceholderTextSelected,
                          select: classes.insuranceSelect,
                          list: classes.insuranceList,
                          listItem: classes.insuranceListItem,
                          itemText: classes.insuranceListItemText,
                          itemHelper: classes.insuranceListitemHelper,
                          selected: classes.selected,
                          menuItem: classes.insuranceMenuItem,
                          menuItemText: classes.insuranceMenuItemText,
                          selectMenuContent: classes.selectMenuContent,
                          inputHelper: classes.inputHelper,
                        }}
                        hasAvatar={false}
                      />
                    </Grid>
                    {[...Array.from(Array(numberOfPairs).keys())].map(
                      (num, i) => {
                        return (
                          <>
                            <Grid item xs={6}>
                              <CustomSingleSelect
                                selectedPairs={selectedClinicId}
                                state={
                                  selectedClinicIdAndDivisionIdPairs[i]
                                    .clinicId ?? ''
                                }
                                type={i + ',ClinicId'}
                                setState={handleClinicIdOrDivisionChange}
                                isMultiple={true}
                                inputTitle={'Clinic ID'}
                                placeholder={'Select'}
                                fullWidth={true}
                                items={clinicIdOptions}
                              />
                            </Grid>
                            {hasFeature && (
                              <Grid item xs={i == numberOfPairs - 1 ? 5 : 6}>
                                <DivisionSelect
                                  // disable={!disableDivisionSelection.split(',')?.includes(i.toString())}
                                  disable={
                                    disabledDivision[i] ||
                                    selectedClinicIdAndDivisionIdPairs[i]
                                      .clinicId !== '9000' // important: hard code for now to filter out the 9000 clinic only NPH-2643
                                  }
                                  state={
                                    selectedClinicIdAndDivisionIdPairs[i]
                                      .divisions ?? []
                                  }
                                  type={i + ',DivisionId'}
                                  setState={handleClinicIdOrDivisionChange}
                                  isMultiple={true}
                                  fullWidth={true}
                                  inputTitle={'Division'}
                                  placeholder={'Select'}
                                  items={divisionIdOptions}
                                />
                              </Grid>
                            )}
                            {i == numberOfPairs - 1 && (
                              <Grid item xs={1}>
                                <IconButton
                                  style={{ marginTop: 35 }}
                                  aria-label="close"
                                  onClick={() => {
                                    setNumberOfPairs(numberOfPairs - 1);
                                    let temp = selectedClinicIdAndDivisionIdPairs.slice(
                                      0,
                                      -1
                                    );
                                    setSelectedClinicIdAndDivisionIdPairs(temp);
                                    setSelectedClinicId(
                                      temp
                                        .map((item) => item.clinicId)
                                        .join(',')
                                    );
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </>
                        );
                      }
                    )}
                    <Grid container xs={12}>
                      <IconButton
                        onClick={() => {
                          setNumberOfPairs(numberOfPairs + 1);
                          setSelectedClinicIdAndDivisionIdPairs([
                            ...selectedClinicIdAndDivisionIdPairs,
                            {
                              clinicId: undefined,
                              divisions: [],
                            },
                          ]);
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                      <div className={classes.buttonText}>Add Clinic ID</div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.inputLabel}>
                        Phone
                      </Typography>
                      <OutlinedInput
                        fullWidth
                        inputProps={{
                          required: true,
                        }}
                        name={'phone'}
                        defaultValue=""
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        error={
                          phoneNumber?.length > 0 &&
                          phoneNumber.match(
                            /^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i
                          ) == null
                        }
                        className={classes.nameInput}
                        placeholder="Enter Phone Number(s): e.g. 234-456-7890"
                        classes={outlinedInputClasses}
                      />
                    </Grid>

                    {/* Enable / Disable MFA only for users whose phone is verified */}
                    {/*  {userData.phoneVerified && (
                      <Grid item xs={12}>
                        <MFACheckbox
                          userId={userData.userId}
                          mfaEnabled={props.mfaEnabled}
                        />
                      </Grid>
                    )} */}
                  </Grid>
                  <Grid item container xs={12} className={classes.cardFooter}>
                    <Button
                      type="submit"
                      className={classes.nextButton}
                      disabled={
                        !formState.isValid ||
                        !(
                          phoneNumber?.match(
                            /^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i
                          ) != null
                        )
                      }
                    >
                      Update User
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    </MainRegion>
  );
};
