import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MainRegion } from '../../../components/MainRegion';
import { Grid, Typography } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { config } from '../../../config';
import LogoWithAppNameColored from '../../../assets/Login/LogoWithAppNameColored.svg';
import MdlandLogoColored from '../../../assets/Login/MdlandLogoColored.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Signer } from '@aws-amplify/core';

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: 280,
    flexGrow: 1,
    justifyContent: 'center',
  },
  footerText: {
    fontSize: 14,
    paddingRight: theme.spacing(1),
    color: '#434343',
    fontWeight: 400,
    lineHeight: '19px',
  },
  footer: {
    position: 'absolute',
    bottom: 56,
    display: 'flex',
    alignItems: 'center',
  },
}));

export function IClinicLogin() {
  const classes = useStyles();
  const backendUrl = 'https://67ae8xr6q3.execute-api.us-east-1.amazonaws.com/prod/landingUserRegister';
  const [error, setError] = React.useState<any>(null);
  const [firstLoading, setFirstLoading] = React.useState(true);
  const navigate = useNavigate();
  const [origin, setOrigin] = React.useState('');



  async function applyToken(token:any, targetUser:any) {
    console.log('Token Time:', new Date().toISOString());
    const keycloakToken = token;
    try {
      // Get AWS credentials using the Keycloak token
      const credentials = await Auth.federatedSignIn(
        'authsrv.mdland.net/auth/realms/mdland_internal',
        { token: keycloakToken, expires_at: Date.now() + 3600 * 1000 },
        { name: 'FederatedUser' }
      );
      console.log(JSON.stringify(credentials));
      const awsAccessKey = credentials.accessKeyId;
      const awsSecretKey = credentials.secretAccessKey;
      const awsSessionToken = credentials.sessionToken;
      console.log(awsAccessKey, awsSecretKey, awsSessionToken);
      Auth.configure({
        credentials: {
          accessKeyId: awsAccessKey,
          secretAccessKey: awsSecretKey,
          sessionToken: awsSessionToken,
        },
      });
      const {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      } = await Auth.currentUserCredentials();
    
      const authConfiguration = Auth.configure(null);
      
      const signaturString = token.split('.')[2];
      const { headers } = Signer.sign(
        {
          url:backendUrl,
          data:JSON.stringify({
            signature: signaturString,
            userId: targetUser
          }),
          headers:{
            'Content-Type': 'application/json'
          },
          method:'POST',
        },
        {
          access_key: accessKeyId,
          secret_key: secretAccessKey,
          session_token: sessionToken,
        },
        { region: authConfiguration.region, service: 'execute-api' }
      );

      
      const signedRequest = {
        method: 'POST',
        url: backendUrl,
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        body: JSON.stringify({
          signature: signaturString,
          userId: targetUser
        }),
      };
     await fetch(signedRequest.url, {
        method: signedRequest.method,
        headers: signedRequest.headers,
        body: signedRequest.body,
        'mode': 'cors',
      });
    } catch (error) {
      console.error('Error getting Cognito token:', error);
      throw error;
    }
  }

  React.useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        // Do we trust the sender of this message?  (might be
        // different from what we originally opened, for example).
        setOrigin(event.origin);
        try {
          if(event.data){
            const json = JSON.parse(event.data);
            if(json.token && json.user && firstLoading){
              applyToken(json.token, json.user).then(() => {
                setFirstLoading(false);
                navigate('/dashboard');
              });
            }
          }
          
        } catch (e: any) {
          console.log(e);
        }
      },
      false
    );
  });

  return (
    <MainRegion>
      <Grid container className={classes.grid}>
        <img src={LogoWithAppNameColored} alt="iPopHealth" />
        {(firstLoading) && (
          <LoadingSpinner
            customText={'Login from iClinic...'}
            style={{ width: '100%', paddingTop: 121.89 }}
          />
        )}
        {error != null ? (
          <div style={{ color: '#B7373E' }}>{error}! Please try again.</div>
        ) : null}
        <div className={classes.footer}>
          <Typography className={classes.footerText}>Powered by</Typography>{' '}
          <img src={MdlandLogoColored} alt="MDLand" />
        </div>
      </Grid>
    </MainRegion>
  );
}
