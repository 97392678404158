import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { SideBar } from './components/SideBar';
import { config } from '../../config';
import { GlobalContext } from '../../components/GlobalContext';
import { Toaster } from '../../components/Toaster';
import { ChromeAlertBar } from '../../components/ChromeAlertBar';
import { NotificationCenter } from './components/NotificationCenter';
import { NotificationAlertMessage } from '../../components/NotificationAlertMessage';
import { MFADialog } from '../../components/MFADialog';

export const SIDEBAR_WIDTH = 250;
const MIN_WIDTH = 1434;
const versionNumber = config.versionNumber;

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    // width: '100vw',

    position: 'relative',
  },
  alertContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 10000,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: SIDEBAR_WIDTH,
    // height: window.innerHeight,
    // height: '100%',
    minHeight: 'calc(100vh - 20px)',
    height: 'calc(100vh - 20px)',
    backgroundColor: theme.palette.grey[100],
  },
  alertMain: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: SIDEBAR_WIDTH,
    // height: window.innerHeight,
    // height: '100%',
    minHeight: 'calc(100vh - 52px)',
    height: 'calc(100vh - 52px)',
    backgroundColor: theme.palette.grey[100],
  },
  versionNum: {
    position: 'absolute',
    right: 28,
    top: 0,
    fontSize: 12,
    lineHeight: '20px',
    color: ' #ABABAB',
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
  },
  footer: {
    height: 20,
    position: 'relative',
    backgroundColor: theme.palette.grey[100],
  },
}));

const MainLayout: React.FC<Props> = (props) => {
  const {
    isOpenToast,
    setIsOpenToast,
    reloadUser,
    toastMessage,
    setIsSticky,
    isChrome,
    setIsChrome,
    isOpenNotificationAlertMessage,
    setIsOpenNotificationAlertMessage,
    notificationAlertMessage,
    MFADialogState,
  } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (isOpenToast) {
      reloadUser();
    }
  }, [isOpenToast]);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    function handleWindowResize() {
      // set sticky header if the window size is >= MIN_WIDTH
      if (anchorRef.current && anchorRef.current.offsetWidth < MIN_WIDTH) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [anchorRef]);

  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div id="alertBarContainer" className={classes.alertContainer}>
        {!isChrome && <ChromeAlertBar setChrome={setIsChrome} />}
      </div>
      <SideBar width={SIDEBAR_WIDTH} />
      <main
        className={isChrome ? classes.main : classes.alertMain}
        ref={anchorRef}
      >
        {isOpenToast && (
          <Toaster
            open={isOpenToast}
            setOpen={setIsOpenToast}
            alertMessage={toastMessage}
          />
        )}

        {/* notification message */}
        {isOpenNotificationAlertMessage && (
          <NotificationAlertMessage
            open={isOpenNotificationAlertMessage}
            setOpen={setIsOpenNotificationAlertMessage}
            alertMessage={notificationAlertMessage}
          />
        )}
        {/* notification center */}
        <NotificationCenter />
        {children}
        {MFADialogState.showMFADialog && <MFADialog />}
      </main>
      <div className={classes.footer}>
        <div className={classes.versionNum} id={versionNumber}>
          Powered by MDLand
          {process.env.REACT_APP_ENV != 'prod' ? ' ' + versionNumber : ''}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
